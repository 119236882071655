import { create } from '@theme/create';

export const themed = create(
  new (function () {
    // FiltersSidebarShell theme
    this.shell = {
      display: ['block', 'none'],
      zIndex: 1000,
      position: 'fixed',
      width: '100%',
      height: 'var(--viewport-height)',
      bg: 'background',
      left: 0,
      bottom: 0,
      transition: 'transform .3s ease-in-out',
    };

    this.scroll = {
      height: 'calc(var(--viewport-height) - 32px)',
      overflowY: 'scroll',
      webkitOverflowScrolling: 'touch',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    };

    this.grid = {
      gridTemplateColumns: '1fr',
      gridGap: 0,
      pl: 0,
    };

    // Open accordion button theme
    this.button = (isLast) => ({
      minHeight: '46px',
      px: 10,
      py: 4,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      borderBottom: (t) => `1px solid ${t.colors.gray}`,
      borderBottomStyle: isLast ? 'none' : 'solid',
    });

    // FiltersSidebarHeader theme
    this.header = {
      variant: 'flex.row.center.center',
      height: '52px',
      borderBottom: (t) => `1px solid ${t.colors.gray}`,
      position: 'sticky',
      top: 0,
      bg: 'background',
      zIndex: 1,

      backButton: {
        position: 'absolute',
        top: '50%',
        left: 8,
        transform: 'translateY(-50%)',
      },

      icon: {
        width: '24px',
        height: '24px',
        color: 'dark',
      },

      heading: {
        fontSize: '20px',
        lineHeight: 24,
        textAlign: 'center',
        letterSpacing: '-0.005em',
        fontWeight: 500,
      },

      clearAll: {
        position: 'absolute',
        top: '50%',
        right: 8,
        transform: 'translateY(-50%)',
      },
    };

    this.accordionHeader = {
      variant: 'text.label.1',
      display: 'inline-block',
    };

    // Sort theme
    this.swatchOuter = (isActive) => ({
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      position: 'relative',
      overflow: 'hidden',
      bg: 'white',
      borderStyle: isActive ? 'solid' : 'none',
      borderWidth: '1px',
      borderColor: 'text',
      mr: 7,
    });

    this.swatchInner = (isActive) => ({
      width: isActive ? 'calc(100% - 4px)' : '100%',
      height: isActive ? 'calc(100% - 4px)' : '100%',
      borderRadius: '50%',
      borderWidth: '1px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      overflow: 'hidden',
      bg: isActive ? 'text' : 'white',
      borderStyle: isActive ? 'none' : 'solid',
      borderColor: 'gray',
    });

    this.checkmark = () => ({
      color: 'white',
      width: '8px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    });

    this.label = (isActive) => ({
      variant: 'text.lg',
      color: isActive ? 'text' : 'dark',
      textDecoration: isActive ? 'underline' : 'unset',
      textUnderlineOffset: '2px',
      textDecorationThickness: '1px',
      textAlign: 'left',
      flex: 1,
    });

    this.selectedSort = {
      fontSize: 3,
      color: 'mediumDarkGray',
      ml: 4,
      textTransform: 'none',
    };
  })()
);
