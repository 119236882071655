import { Heading, Flex, Paragraph } from 'theme-ui';

import { convertAlignToFlex } from '@utils/common';
import { Markdown, Link } from '@snippets';

import { themed } from './Content.theme';

export const Content = themed(({ theme, content }) => {
  const textAlign = [content?.alignment, content?.alignmentDt];
  const flexAlign = textAlign.map((align) => convertAlignToFlex(align));

  return (
    <Flex data-comp={Content.displayName} sx={theme.outer}>
      <Flex
        sx={{
          variant: 'flex.column',
          width: '100%',
          maxWidth: ['600px', '450px'],
          alignItems: flexAlign,
        }}
      >
        {content?.superheading && (
          <Paragraph
            sx={{
              ...theme.superheading,
              textAlign,
            }}
          >
            {content.superheading}
          </Paragraph>
        )}

        <Heading
          sx={{
            ...theme.heading,
            textAlign,
          }}
        >
          {content?.heading}
        </Heading>

        {content?.body && (
          <Markdown
            text={content.body}
            sx={{
              ...(content?.heading
                ? theme.bodyWithHeading
                : theme.bodyWithoutHeading),
            }}
            textSx={{
              textAlign,
            }}
          />
        )}

        {content?.cta?.text && (
          <Link
            variant={`buttons.${content?.ctaStyle || 'secondary'}`}
            href={content?.cta?.url}
            newTab={content?.cta?.newTab}
            sx={{
              mt: content?.heading || content?.body ? [10, 11] : 0,
              alignSelf: flexAlign,
            }}
          >
            {content?.cta?.text}
          </Link>
        )}
      </Flex>
    </Flex>
  );
});

Content.displayName = 'Content';
