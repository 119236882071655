import { useRouter } from 'next/router';
import { Fragment, useMemo, useEffect, useState } from 'react';
import { Flex, Paragraph, Box } from 'theme-ui';

import { Link, Svg } from '@snippets';
import store, { useRecoilValue } from '@store';
import { themed } from './Breadcrumbs.theme';

const storeCrumbs = (crumbs) => {
  window.sessionStorage?.setItem('crumbs', JSON.stringify(crumbs));
};

const readCrumbs = () => {
  let crumbs = window.sessionStorage?.getItem('crumbs');
  if (!crumbs) {
    crumbs = [];
    storeCrumbs(crumbs);
  } else {
    crumbs = JSON.parse(crumbs);
  }
  return crumbs;
};

export const Breadcrumbs = themed(
  ({
    isHome,
    isSuperCollection,
    superCollectionTitle,
    isCollection,
    collectionTitle,
    isPdp,
    selectedVariant,
    collection,
    theme,
  }) => {
    const router = useRouter();
    const activeRoute = router.asPath;

    const [breadCrumbs, setBreadCrumbs] = useState([]);
    const subCollections = useRecoilValue(store.collectionsMap);

    const superCollectionCrumb = useMemo(() => {
      if (!isSuperCollection) return null;
      return {
        title: superCollectionTitle,
        url: activeRoute,
      };
    }, [activeRoute, isSuperCollection, superCollectionTitle]);

    const collectionCrumb = useMemo(() => {
      if (!isCollection) return null;
      return {
        title: collectionTitle,
        url: activeRoute,
      };
    }, [activeRoute, isCollection, collectionTitle]);

    const isSubCollection = useMemo(() => {
      if (!subCollections) return null;
      const active = subCollections?.find((item) =>
        item?.url?.includes(activeRoute)
      );
      return active;
    }, [activeRoute, subCollections]);

    const productCrumb = useMemo(() => {
      if (!isPdp) return null;

      const titleArr = selectedVariant?.product?.title?.split('|');
      const productColor = titleArr?.[1]?.trim() || '';
      const productTitle = titleArr?.[0]?.trim() || '';

      if (productTitle !== null && productColor !== null) {
        return {
          title: `${productColor} ${productTitle}`,
          titleMobile:
            `${productColor} ${productTitle}`.length > 38
              ? `${productTitle}`
              : `${productColor} ${productTitle}`,
          url: activeRoute,
        };
      }
      return null;
    }, [selectedVariant?.product?.title, activeRoute, isPdp]);

    useEffect(() => {
      if (isHome) {
        storeCrumbs([
          {
            title: 'Home',
            url: '/',
          },
        ]);
      }
      if (isSuperCollection) {
        storeCrumbs([
          {
            title: 'Home',
            url: '/',
          },
        ]);
        const crumbs = readCrumbs();
        crumbs?.push(superCollectionCrumb);
        storeCrumbs(crumbs);
      } else if (isCollection && isSubCollection) {
        const crumbs = readCrumbs();
        const hasProductCrumb = crumbs?.find((crumb) =>
          crumb?.url?.includes('products')
        );
        if (hasProductCrumb) {
          crumbs.splice(-1);
          storeCrumbs(crumbs);
        } else {
          crumbs?.push(collectionCrumb);
          storeCrumbs(crumbs);
        }
      } else if (isCollection) {
        storeCrumbs([
          {
            title: 'Home',
            url: '/',
          },
        ]);
        const crumbs = readCrumbs();
        crumbs?.push(collectionCrumb);
        storeCrumbs(crumbs);
      } else if (isPdp) {
        const crumbs = readCrumbs();
        if (
          crumbs?.findIndex(
            (crumb) => crumb?.url === '/' && crumb?.title === 'Home'
          ) !== -1
        ) {
          crumbs.splice(0, 1);
        }
        const alreadyExist = crumbs?.find(
          (crumb) =>
            crumb?.title === productCrumb?.title &&
            crumb?.url.includes('products')
        );
        const isProductVariant = crumbs?.findIndex((crumb) =>
          crumb?.url.includes('products')
        );
        const hasCollection = crumbs?.findIndex((crumb) =>
          crumb?.url.includes('/collections')
        );
        const existCollection = crumbs?.find(
          (crumb) =>
            crumb?.url.includes('collections') &&
            crumb?.url === `/collections/${collection?.handle}`
        );
        if (isProductVariant !== -1) {
          crumbs.splice(isProductVariant, 1, productCrumb);
        } else {
          crumbs?.push(productCrumb);
        }
        if (collection?.handle && !existCollection) {
          if (hasCollection !== -1) {
            crumbs.splice(hasCollection, 1, {
              title: collection?.title,
              url: `/collections/${collection?.handle}`,
            });
          } else {
            crumbs.splice(-1, 0, {
              title: collection?.title,
              url: `/collections/${collection?.handle}`,
            });
          }
        }
        storeCrumbs(crumbs);
      }

      const crumbs = readCrumbs();
      setBreadCrumbs(crumbs?.filter((crumb) => crumb !== null));
    }, [
      superCollectionCrumb,
      collectionCrumb,
      productCrumb,
      isCollection,
      isSubCollection,
      isSuperCollection,
      isPdp,
      isHome,
      collection?.handle,
    ]);

    if (isHome) return null;

    return (
      <Flex data-comp={Breadcrumbs.displayName} sx={theme.wrapper}>
        {breadCrumbs?.map((crumb, index) => {
          const isLast =
            index === breadCrumbs.length - 1 || crumb?.url === router.asPath;
          return (
            <Fragment key={index}>
              {isLast && crumb?.title ? (
                <Box className="flex-shrink overflow-hidden">
                  <Paragraph sx={{ ...theme.itemTitle, width: '100%' }}>
                    {isPdp && crumb?.titleMobile ? (
                      <>
                        <span className="max-sm:hidden">{crumb?.title}</span>
                        <span className="inline-block sm:hidden whitespace-nowrap overflow-hidden overflow-ellipsis w-full">
                          {crumb?.titleMobile}
                        </span>
                      </>
                    ) : (
                      <span>{crumb?.title}</span>
                    )}
                  </Paragraph>
                </Box>
              ) : null}
              {!isLast && crumb?.title ? (
                <Link
                  href={crumb?.url}
                  sx={theme.itemTitleLink}
                  className="flex-shrink-0"
                >
                  {crumb?.title}
                </Link>
              ) : null}

              {!isLast && (
                <Svg
                  src="/svgs/chevron/right.svg#right"
                  alt=""
                  viewBox="0 0 16 16"
                  draggable={false}
                  sx={theme.icon}
                  className="flex-shrink-0"
                />
              )}
            </Fragment>
          );
        })}
      </Flex>
    );
  }
);

Breadcrumbs.displayName = 'Breadcrumbs';
