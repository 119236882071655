import { useEffect, useState, useCallback, useRef } from 'react';
import { useRouter } from 'next/router';
import { useCustomer } from '@backpackjs/storefront';
import store, { useRecoilState } from '@store';
import { Heading, Paragraph } from 'theme-ui';
import { Section } from '@snippets';
import Swiper, { A11y, Navigation, Pagination } from 'swiper';
import { NavigationButton } from '@snippets/ProductSwiper/Navigation/NavigationButton';
import { useProductSwiperParams } from '@snippets/ProductSwiper//useProductSwiperParams';
import { themed } from './LoyaltylionRewardsList.theme';
import { Schema } from './LoyaltylionRewardsList.schema';

export const LoyaltylionRewardsList = themed(({ theme, cms }) => {
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const customer = useCustomer();
  const [loyaltylionLoaded] = useRecoilState(store.loyaltylionLoaded);
  const {
    state,
    userTag,
    heading,
    headingColor,
    subHeading,
    rewardsType,
    rewardIds,
    displayType,
    icons,
    section,
  } = cms;
  const textAlign = [cms?.alignment, cms?.alignmentDt];
  const [loyaltylionRendered, setLoyaltylionRendered] = useState(false);
  const { asPath } = useRouter();
  const [swiper, setSwiper] = useState(null);
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [loop, setLoop] = useState(true);
  const [count, setCount] = useState(true);
  const [initializing, setInitializing] = useState(false);
  const [itemCount, setItemCount] = useState(0);
  const navRef = useRef(null);
  const sliderEnable = displayType === 'slider';
  const swiperParams = useProductSwiperParams({
    isProductRecs: true,
    loopEnabled: loop,
    count,
  });
  const customerTags = customer?.tags || [];

  const iconsStyle = (icons || []).reduce((acc, icon, index) => {
    if (icon?.icon?.src) {
      return [
        ...acc,
        `#${moduleID} .lion-rewards-list .lion-reward-item${
          index + 1
        }{--loyaltyLion-reward-icon: url("${icon?.icon?.src}")}`,
      ];
    }
    return acc;
  }, []);

  const rewardShowClasses = (rewardIds || []).reduce((acc, id, index) => {
    if (index === 0) {
      acc.push(
        `#${moduleID} .lion-rewards-list .lion-reward-item{display: none;}`
      );
    }
    return [
      ...acc,
      `#${moduleID} .lion-rewards-list .lion-reward-item[data-reward-id="${id.trim()}"]{display: flex;}`,
    ];
  }, []);

  const disableRewardIds = customerTags.reduce((acc, id) => {
    if (id.indexOf('_rewardId:') === 0) {
      const idInt = id.replace('_rewardId:', '');
      return [...acc, idInt];
    }
    return acc;
  }, []);

  const enable =
    state === 'enable' ||
    (state === 'guest' && !customer?.id) ||
    (state === 'user' && customer?.id) ||
    (state === 'userByTag' &&
      customer?.id &&
      userTag &&
      userTag !== '' &&
      customer?.tags?.includes(userTag)) ||
    (state === 'userWithoutTag' &&
      customer?.id &&
      userTag &&
      userTag !== '' &&
      !customer?.tags?.includes(userTag));

  useEffect(() => {
    let i = 0;
    const t = window.setInterval(() => {
      if (
        loyaltylionLoaded &&
        document.querySelector(`#${moduleID} .lion-reward-item`)
      ) {
        const rewardItems = Array.from(
          document.querySelector(`#${moduleID} .lion-reward-item`)
        );
        i++;
        if (rewardItems?.length && rewardItems?.length !== itemCount) {
          setItemCount(rewardItems.length);
        } else {
          window.clearInterval(t);
          setLoyaltylionRendered(true);
        }
      } else if (i > 10) {
        window.clearInterval(t);
        setLoyaltylionRendered(false);
      }
    }, 500);
    return () => {
      setLoyaltylionRendered(false);
    };
  }, [loyaltylionLoaded, asPath]);

  useEffect(() => {
    if (
      loyaltylionLoaded &&
      rewardsType !== 'all' &&
      loyaltylionRendered &&
      !swiper
    ) {
      let rewardsClass;
      if (rewardsType === 'productDiscount') {
        rewardsClass = `#${moduleID} .lion-reward-item--cart-discount-voucher, #${moduleID} .lion-reward-item--checkout-redemption`;
      } else if (rewardsType === 'cartDiscount') {
        rewardsClass = `#${moduleID} div[class*=lion-reward-item--product], #${moduleID} .lion-reward-item--checkout-redemption`;
      } else if (rewardsType === 'rewardIds') {
        rewardsClass = `#${moduleID} div[class*=lion-reward-item--product], #${moduleID} .lion-reward-item--checkout-redemption`;
      }
      if (rewardsClass) {
        const rewardsEl = Array.from(
          document.querySelectorAll(`${rewardsClass}`)
        );
        rewardsEl.forEach((el) => {
          el.parentElement.removeChild(el);
        });
      }

      const swiperEl = document.createElement('div');
      swiperEl.className = 'swiper-wrapper';
      const rewards = Array.from(
        document.querySelector(`#${moduleID} .lion-rewards-list`).children
      ).map((child) => child);
      rewards.forEach((child, index) => {
        child.classList.add(`lion-reward-item${index + 1}`);
        const rewardId = child?.dataset?.rewardId;
        if (disableRewardIds?.length && disableRewardIds?.includes(rewardId)) {
          const actionBtn = child.querySelector('.lion-action-button');
          if (actionBtn) {
            actionBtn.classList.add('lion-action-button--disabled');
            actionBtn.classList.add('lion-action-button--completed');
            actionBtn.disabled = true;
            actionBtn.innerHTML =
              '<span class="lion-reward-item__redeem-button-text lion-loyalty-page-reward-item__redeem-button-text">REDEEMED</span>';
          }
        }
        if (sliderEnable) {
          child.classList.add('swiper-slide');
          swiperEl.appendChild(child);
        }
      });
      if (sliderEnable) {
        setCount(swiperEl.children?.length >= 4);
        setLoop(!!count);

        if (navRef?.current && navRef?.current?.parentElement) {
          const swiperNavs = Array.from(
            navRef.current.querySelectorAll('button')
          );
          swiperNavs.forEach((button) => {
            document
              .querySelector(`#${moduleID} .lion-rewards-list`)
              .appendChild(button);
          });
          navRef.current.parentElement.removeChild(navRef.current);
        }
        document
          .querySelector(`#${moduleID} .lion-rewards-list`)
          .prepend(swiperEl);
      }
      setInitializing(sliderEnable);
      if (
        loyaltylionRendered &&
        document.querySelector(`#${moduleID} [data-lion-rewards-list]`)
      ) {
        document
          .querySelector(`#${moduleID} [data-lion-rewards-list]`)
          .classList.remove('hidden');

        const getVariantInventory = async (variantId) => {
          const endpoint = '/api/productVariant';
          const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              variantId,
            }),
          };

          const response = await fetch(endpoint, options);
          const body = await response.json();
          if (body?.productVariant) {
            return body.productVariant;
          }
          return {};
        };

        const setButtonState = (productRewards) => {
          (productRewards || []).forEach(async (reward) => {
            const product = reward?.target_products?.[0];
            const rewardId = reward.id;
            if (product?.variant_id) {
              const variant = await getVariantInventory(product.variant_id);
              if (!variant?.availableForSale) {
                const actionBtn = document.querySelector(
                  `#${moduleID} [data-lion-rewards-list] .lion-reward-item[data-reward-id="${rewardId}"] .lion-action-button`
                );
                if (actionBtn) {
                  actionBtn.classList.add('lion-action-button--disabled');
                  actionBtn.classList.add('lion-action-button--completed');
                  actionBtn.disabled = true;
                  actionBtn.innerHTML =
                    '<span class="lion-reward-item__redeem-button-text lion-loyalty-page-reward-item__redeem-button-text">Out of Stock</span>';
                }
              }
            }
          });
        };

        const productRewards = (
          window?.loyaltylion?.program?.rewards || []
        ).reduce((acc, reward) => {
          if (reward?.target_type === 'product') {
            return [...acc, reward];
          }
          return acc;
        }, []);
        setButtonState(productRewards);
      }
    }
  }, [loyaltylionRendered, asPath]);

  useEffect(() => {
    if (!swiper && initializing && sliderEnable) {
      const swiperSlide = new Swiper(`#${moduleID} .lion-rewards-list`, {
        ...swiperParams,
        modules: [Navigation, Pagination, A11y],
        loop,
        navigation: {
          nextEl: '.swiper-button-custom-next',
          prevEl: '.swiper-button-custom-prev',
        },
      });
      setSwiper(swiperSlide);
    }
  }, [initializing]);

  const setStartAndEndOnSwiperChange = useCallback(() => {
    if (!swiper || !sliderEnable) return;

    if (loop) {
      setIsStart(false);
      setIsEnd(false);
    }

    if (!loop) {
      setIsStart(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
      swiper.on('slideChange', () => {
        setIsStart(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      });
    }
  }, [swiper, loop]);

  useEffect(() => {
    setStartAndEndOnSwiperChange();
  }, [swiper, loop]);

  if (!enable) return null;

  return (
    <Section
      id={moduleID}
      data-comp={LoyaltylionRewardsList.displayName}
      section={section}
    >
      <div className="py-10">
        {heading && (
          <Heading
            sx={{
              ...theme.heading,
              textAlign,
              color: headingColor || 'text',
            }}
          >
            {heading}
          </Heading>
        )}
        {subHeading && (
          <Paragraph
            sx={{
              ...theme.paragraph,
              textAlign,
              color: headingColor || 'text',
            }}
            dangerouslySetInnerHTML={{ __html: subHeading }}
          />
        )}
        <div
          className="m-auto block"
          sx={{
            ...theme[`only${rewardsType}`],
            ...theme.slides,
          }}
        >
          <div data-lion-rewards-list className="hidden" />
          {!!sliderEnable && (
            <div ref={navRef}>
              <NavigationButton
                className="swiper-button-custom-prev after:hidden"
                inactive={isStart}
                count={count}
                alt="Previous product"
              />

              <NavigationButton
                className="swiper-button-custom-next after:hidden"
                src="/svgs/arrow-right.svg#arrow-right"
                alt="Next product"
                inactive={isEnd}
                isNext
                count={count}
              />
            </div>
          )}
        </div>
      </div>
      {!!iconsStyle?.length && <style>{iconsStyle.join('')}</style>}
      {!!rewardShowClasses?.length && (
        <style>{rewardShowClasses.join('')}</style>
      )}
    </Section>
  );
});

LoyaltylionRewardsList.displayName = 'Loyaltylion Rewards List';
LoyaltylionRewardsList.Schema = Schema;
