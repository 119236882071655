import { useMemo, useEffect, useCallback } from 'react';
import { Box } from 'theme-ui';
import { useModal } from '@hooks';
import PropTypes from 'prop-types';
import MarkdownJsx from 'markdown-to-jsx';

import { HalfMedia } from '../HalfMedia';

import { themed } from './Markdown.theme';

const textHtmlTags = `p, h1, h2, h3, h4, h5, h6, span, label, div, strong, em, blockquote, pre, code, i, b, ul, ol, li, modal`;

export const Markdown = themed(
  ({
    theme,
    inputRef,
    text,
    children,
    sx,
    textSx, // Sx applied across all markdown to preserve a specific style, e.g. headings, descriptions
    textAlign,
    setIsLoaded,
    overrides,
    ...props
  }) => {
    const passedText = typeof children === 'string' ? children : text || '';
    const markdownText = useMemo(() => {
      return passedText
        ?.replaceAll('<modal', '<Modal className="markdown-modal"')
        .replaceAll('</modal', '</Modal')
        .replace(
          /\(modal\s(.*?)\)(.*?)\(\/modal\)/gi,
          '<Modal className="markdown-modal" $1>$2</Modal>'
        );
    }, [passedText]);

    const [{ modal }, { openModal, closeModal }] = useModal();

    const toggleModal = useCallback(
      (e) => {
        const passedId =
          e?.target?.id !== '' ? e?.target?.id : e?.target?.parentElement?.id;
        if (modal) {
          closeModal();
          return;
        }
        openModal(<HalfMedia passedId={passedId} />);
      },
      [modal, HalfMedia]
    );

    useEffect(() => {
      if (typeof setIsLoaded === 'function') {
        setIsLoaded(true);
      }
    }, []);

    return (
      <Box
        data-comp={Markdown.displayName}
        ref={inputRef}
        {...props}
        sx={{
          ...theme.markdown,
          textAlign,
          [textHtmlTags]: {
            textAlign: textAlign || null,
            mt: textSx ? 0 : null,
            '&:last-of-type': textSx ? { mb: 0 } : {},
            ...textSx,
          },
          ...sx,
        }}
      >
        <MarkdownJsx
          options={{
            forceBlock: true,
            overrides: {
              Modal: {
                component: 'span',
                props: {
                  onClick: toggleModal,
                },
              },
              ...overrides,
            },
          }}
        >
          {markdownText}
        </MarkdownJsx>
      </Box>
    );
  }
);

Markdown.displayName = 'Markdown';

Markdown.propTypes = {
  text: PropTypes.string,
  children: PropTypes.string,
  sx: PropTypes.object,
  textAlign: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  textSx: PropTypes.object,
};
