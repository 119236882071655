import { store } from '@backpackjs/storefront';

const overlay = store.recoil.atom({
  key: 'global/overlay',
  default: null,
});

const modal = store.recoil.atom({
  key: 'global/modal',
  default: null,
});

const sidebar = store.recoil.atom({
  key: 'global/sidebar',
  default: null, // null || 'cart' || 'search'
});

const cookiesMap = store.recoil.atom({
  key: 'global/cookiesMap',
  default: {},
});

const blogCategory = store.recoil.atom({
  key: 'global/blogCategory',
  default: null,
});

const elevarMarketingObject = store.recoil.atom({
  key: 'global/elevarMarketingObject',
  default: null,
});

const shopperId = store.recoil.atom({
  key: 'global/shopperId',
  default: null,
});

const collectionsMap = store.recoil.atom({
  key: 'global/collectionsMap',
  default: [],
});

const loyaltylionLoaded = store.recoil.atom({
  key: 'global/loyaltylionLoaded',
  default: false,
});

const videoWiseLoaded = store.recoil.atom({
  key: 'global/videoWiseLoaded',
  default: false,
});

const videoWiseConfig = store.recoil.atom({
  key: 'global/videoWiseConfig',
  default: false,
});

const rewardsRedirect = store.recoil.atom({
  key: 'global/rewardsRedirect',
  default: false,
});

// Access to Password Protected Pages
// starts as true on first time visit. Value then updates on mount
// when cookie OR gatedPage has been found in usePasswordProtected.js
const hasAccess = store.recoil.atom({
  key: 'hasAccess',
  default: true,
});

const gatedPage = store.recoil.atom({
  key: 'gatedPage',
  default: null,
});

export default {
  overlay,
  modal,
  sidebar,
  cookiesMap,
  blogCategory,
  elevarMarketingObject,
  shopperId,
  collectionsMap,
  loyaltylionLoaded,
  videoWiseLoaded,
  videoWiseConfig,
  hasAccess,
  gatedPage,
  rewardsRedirect,
};
