import { create } from '@theme/create';

export const themed = create(
  new (function () {
    this.wrapper = {
      minHeight: '34px',
      mb: 6,
      display: ['none', 'flex'],
      top: ['0', 'var(--header-height)'],
      position: 'sticky',
      zIndex: 2,
      bg: 'white',
      py: [0, '8px'],
    };

    this.dropdown = {
      width: ['100%', '210px'],
      '[data-comp=DropdownDrawer]': {
        borderColor: 'gray',
      },
    };

    this.selected = {
      width: '100%',
      p: 8,
      borderStyle: 'solid',
      borderColor: 'gray',
      bg: 'background',
      transition: 'all 0.2s ease-in-out',
      borderRadius: [0, '22px'],
      ':hover': {
        borderColor: 'mediumDarkGray',
      },

      content: {
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      },

      title: {
        variant: 'text.label.1',
      },

      icon: {
        transition: 'transform 0.2s ease-in-out',
        width: '20px',
      },
    };

    this.option = {
      width: '100%',
      px: 8,

      title: {
        variant: 'text.base',
        fontSize: 2,
        lineHeight: 20,
        width: '100%',
        ':hover': {
          fontWeight: 500,
        },
      },
    };
  })()
);
