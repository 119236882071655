import { useCallback, useEffect } from 'react';
import { useRouter } from '@backpackjs/storefront';

import { useSearchspring } from '@hooks';
import store, { useRecoilState, useRecoilValue } from '@store';

import { useCollectionSort } from './useCollectionSort';
import { useCollectionFilters } from './useCollectionFilters';

export const useCollection = () => {
  const router = useRouter();
  const { requestSearchspring } = useSearchspring();
  const [{ selectedSort }, { sortCollection }] = useCollectionSort();
  const [{ selectedFilters }] = useCollectionFilters();

  const resultsPerPage = useRecoilValue(store.selectedResultsPerPage);
  const currentPage = useRecoilValue(store.currentResultsPage);
  const [collection, setCollection] = useRecoilState(store.collection);
  const collectionHandle = router?.query?.handle;
  const { asPath } = router;

  const deps = [
    collectionHandle,
    selectedSort?.field,
    selectedSort?.direction,
    JSON.stringify(selectedFilters),
    resultsPerPage,
    currentPage,
    asPath,
  ];

  // Get collection from ss
  const getCollection = useCallback(async () => {
    try {
      const data = await requestSearchspring({
        action: 'getCollection',
        params: {
          collectionHandle,
          sort: selectedSort,
          filters: selectedFilters,
          page: currentPage,
          resultsPerPage,
          domain: `${process.env.SITE_URL}/${asPath?.split('?')[0]}`, // full url of current page
        },
      });

      let _data = data;
      const banners = data?.merchandising?.content?.inline;

      if (banners?.length) {
        const results = banners.reduce(
          (arr, item, index) => {
            const pos = item.config.position.index;

            if (
              pos >= (currentPage - 1) * _data?.pagination?.perPage &&
              pos < currentPage * _data?.pagination?.perPage
            ) {
              const banner = {
                isBanner: true,
                value: item.value,
                id: `banner-index-${index}`,
              };

              arr.splice(
                pos - (currentPage - 1) * _data?.pagination?.perPage,
                0,
                banner
              );
            }

            return arr;
          },
          [..._data.results]
        );
        _data = { ..._data, results };
      }

      setCollection(_data || null);
    } catch (error) {
      console.error(`Error with getCollection: ${error.message}`);
      throw error;
    }
  }, [...deps]);

  useEffect(() => {
    getCollection();
  }, [...deps]);

  useEffect(() => {
    return () => setCollection(null);
  }, []);

  useEffect(() => {
    sortCollection({ field: null, direction: null });
    return () => sortCollection({ field: null, direction: null });
  }, [collectionHandle]);

  return {
    products: collection?.results || null,
    merchandising: collection?.merchandising || null,
  };
};

// Example data from api
// {
//   "pagination": {
//       "totalResults": 5,
//       "begin": 1,
//       "end": 5,
//       "currentPage": 1,
//       "totalPages": 1,
//       "previousPage": 0,
//       "nextPage": 0,
//       "perPage": 40,
//       "defaultPerPage": 40
//   },
//   "sorting": {
//       "options": [
//           {
//               "field": "ss_days_since_published",
//               "direction": "asc",
//               "label": "Newest"
//           },
//           {
//               "field": "title",
//               "direction": "asc",
//               "label": "Name: A - Z"
//           },
//           {
//               "field": "title",
//               "direction": "desc",
//               "label": "Name: Z - A"
//           },
//           {
//               "field": "ss_price",
//               "direction": "asc",
//               "label": "Price: Low to High"
//           },
//           {
//               "field": "ss_price",
//               "direction": "desc",
//               "label": "Price: High to Low"
//           }
//       ]
//   },
//   "resultLayout": "grid",
//   "results": [
//       {
//           "brand": "CUTS CLOTHING",
//           "handle": "black-mojave-short",
//           "id": "2e912d3c4c4d49e687c13d813b501dae",
//           "imageUrl": "https://cdn.shopify.com/s/files/1/1368/3463/products/BLACK-FESTIVAL-SHORT_720x.jpg?v=1648146050",
//           "intellisuggestData": "eJwyNzIpLK5isPQrzw719fE3DjFJDPWtSA2y8M0LZGDwdTI0MDQz99M1MDDUjQhmMGQwZDBgMGVIL8pMAQQAAP__17AOsg",
//           "intellisuggestSignature": "d9afe41bf21cb76b44aaf1e6561fab57bc8dac6813c6142be307c5bd9566c86a",
//           "msrp": "0",
//           "name": "Mojave Short | Black",
//           "price": "74",
//           "product_type_unigram": "black",
//           "sku": "MB10167N-001-XS",
//           "ss_available": "1",
//           "ss_has_image": "1",
//           "ss_id": "3.9872671973464e+13",
//           "ss_instock_pct": "50",
//           "ss_inventory_count": "210",
//           "ss_is_published": "1",
//           "thumbnailImageUrl": "https://cdn.shopify.com/s/files/1/1368/3463/products/BLACK-FESTIVAL-SHORT_720x.jpg?v=1648146050",
//           "uid": "6918117458008",
//           "url": "https://www.cutsclothing.com/products/black-mojave-short"
//       },
//       {
//           "brand": "CUTS CLOTHING",
//           "handle": "river-hyperloop-sunday-sweatpant",
//           "id": "7e74fc3fbc925baa0755fd46e874f0f7",
//           "imageUrl": "https://cdn.shopify.com/s/files/1/1368/3463/products/RIVER-HYPERLOOP-JOGGER-2.0_720x.jpg?v=1647284510",
//           "intellisuggestData": "eJwyNzIpLK5isPQrzw719fE3DjFJDPWtSA2y8M0LZGDwdTI0MDQy9NE1MTbSjQhmMGQwYjBgMGVIL8pMAQQAAP__10MOrw",
//           "intellisuggestSignature": "2bc76ecf1d129d42c328a19fdc61852929e627acb582b073e0ea5a712d7f844b",
//           "msrp": "0",
//           "name": "Sunday Sweatpant 2.0 | River Slim-fit Hyperloop",
//           "price": "98",
//           "product_type_unigram": "hyperloop",
//           "sku": "MB10121L-432-XS",
//           "ss_available": "1",
//           "ss_has_image": "1",
//           "ss_id": "3.9844233052248e+13",
//           "ss_instock_pct": "83",
//           "ss_inventory_count": "86",
//           "ss_is_published": "1",
//           "thumbnailImageUrl": "https://cdn.shopify.com/s/files/1/1368/3463/products/RIVER-HYPERLOOP-JOGGER-2.0_720x.jpg?v=1647284510",
//           "uid": "6922038050904",
//           "url": "https://www.cutsclothing.com/products/river-hyperloop-sunday-sweatpant"
//       },
//       ...
//   ],
//   "facets": [
//       {
//           "field": "collection_name",
//           "label": "Collection",
//           "type": null,
//           "multiple": "",
//           "collapse": 0,
//           "facet_active": 0,
//           "values": [
//               {
//                   "active": false,
//                   "type": "value",
//                   "value": "ALL BOTTOMS",
//                   "label": "ALL BOTTOMS",
//                   "count": 5
//               },
//               {
//                   "active": false,
//                   "type": "value",
//                   "value": "ALL PRODUCTS",
//                   "label": "ALL PRODUCTS",
//                   "count": 5
//               },
//               {
//                   "active": false,
//                   "type": "value",
//                   "value": "L",
//                   "label": "L",
//                   "count": 5
//               },
//               {
//                   "active": false,
//                   "type": "value",
//                   "value": "M",
//                   "label": "M",
//                   "count": 5
//               },
//               {
//                   "active": false,
//                   "type": "value",
//                   "value": "Low Inventory",
//                   "label": "Low Inventory",
//                   "count": 3
//               },
//               ...
//           ]
//       },
//       {
//           "field": "product_type",
//           "label": "Product Type",
//           "type": null,
//           "collapse": 0,
//           "facet_active": 0,
//           "values": [
//               {
//                   "active": false,
//                   "type": "value",
//                   "value": "Bottoms",
//                   "label": "Bottoms",
//                   "count": 5
//               }
//           ]
//       },
//       {
//           "field": "ss_price",
//           "label": "Price",
//           "type": "list",
//           "collapse": 0,
//           "facet_active": 0,
//           "values": [
//               {
//                   "active": false,
//                   "type": "range",
//                   "low": "60",
//                   "high": "80",
//                   "label": "$60 to $80",
//                   "count": 1
//               },
//               {
//                   "active": false,
//                   "type": "range",
//                   "low": "80",
//                   "high": "100",
//                   "label": "$80 to $100",
//                   "count": 3
//               },
//               {
//                   "active": false,
//                   "type": "range",
//                   "low": "100",
//                   "high": "150",
//                   "label": "$100 to $150",
//                   "count": 1
//               }
//           ]
//       }
//   ],
//   "breadcrumbs": [
//       {
//           "field": "collection_handle",
//           "label": "collection_handle: all-bottoms",
//           "filterLabel": "collection_handle",
//           "filterValue": "all-bottoms",
//           "removeFilters": [],
//           "removeRefineQuery": []
//       }
//   ],
//   "filterSummary": [],
//   "merchandising": {
//       "redirect": "",
//       "is_elevated": [],
//       "elevated": [],
//       "removed": [],
//       "content": {},
//       "facets": [],
//       "facetsHide": [],
//       "experiments": [],
//       "variants": [],
//       "personalized": false,
//       "triggeredCampaigns": null
//   }
// }
