import { Container } from 'theme-ui';

import { withInView } from '@utils';
import { Section } from '@snippets';

import { Schema } from './LinkFarm.schema';
import { Slider } from './Slider';

export const LinkFarm = withInView(
  ({ cms }) => {
    const { section, tiles } = cms;
    return (
      <Section section={section}>
        <Container data-comp={LinkFarm.displayName}>
          {tiles?.length > 0 && <Slider tiles={tiles} />}
        </Container>
      </Section>
    );
  },
  { triggerOnce: true }
);

LinkFarm.displayName = 'LinkFarm';
LinkFarm.Schema = Schema;
