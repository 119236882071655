import { Container, Box, Heading } from 'theme-ui';
import { useEffect } from 'react';

import { Breadcrumbs, CollectionCategories } from '@snippets';
import { withInView } from '@utils';

import store, { useSetRecoilState } from '@store';
import { SuperCollectionGrid } from './SuperCollectionGrid';
import { SuperCollectionSeoFooter } from './SuperCollectionSeoFooter';
import { Schema } from './SuperCollection.schema';
import { themed } from './SuperCollection.theme';

export const SuperCollection = withInView(
  themed(({ cms, theme }) => {
    const { title, tiles, menu, seo } = cms;

    const setCollectionsMap = useSetRecoilState(store.collectionsMap);
    useEffect(() => {
      const titles = menu?.categories?.map((category) => {
        return {
          title: category.link?.url
            .split('/')?.[2]
            ?.split('-')
            .map(function (word) {
              return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' '),
          url: category.link?.url,
        };
      });
      return setCollectionsMap(titles);
    }, [menu?.categories]);

    return (
      <Container data-comp={SuperCollection.displayName}>
        <Breadcrumbs isSuperCollection superCollectionTitle={title} />
        <Box sx={theme.mobileHeader}>
          <Heading as="h1" sx={theme.mobileHeading}>
            {title}
          </Heading>

          <CollectionCategories
            categories={menu?.categories}
            collapsed={menu?.collapsed}
            name={menu?.name}
            isSuperCollection
          />
        </Box>

        {/* Product items grid */}
        <Container sx={theme.mainLayout}>
          <Box sx={theme.desktopHeader}>
            <Heading as="h1" sx={theme.desktopHeading}>
              {title}
            </Heading>

            <CollectionCategories
              categories={menu?.categories}
              collapsed={menu?.collapsed}
              name={menu?.name}
              isSuperCollection
            />
          </Box>

          <SuperCollectionGrid products={tiles?.products} />

          <SuperCollectionSeoFooter body={seo?.body} />
        </Container>
      </Container>
    );
  }),
  { triggerOnce: true }
);

SuperCollection.displayName = 'SuperCollection';
SuperCollection.Schema = Schema;
