import { Heading, Flex, Button } from 'theme-ui';

import { Svg } from '@snippets';

import { useCollectionFilters } from '@templates/collection/hooks';
import { useSearchFilters } from '../Search/SearchPage/Filters/useSearchFilters';

import { themed } from './FiltersSidebar.theme';

export const FiltersSidebarHeader = themed(
  ({ theme, toggleFiltersSidebar, title, isSearchPage }) => {
    const [, { clearSelectedFilters }] = isSearchPage
      ? useSearchFilters()
      : useCollectionFilters();

    return (
      <Flex data-comp={FiltersSidebarHeader.displayName} sx={theme.header}>
        <Button
          variant="buttons.plain"
          onClick={toggleFiltersSidebar}
          sx={theme.header.backButton}
        >
          <Svg
            viewBox="0 0 24 24"
            src="/svgs/arrow-left.svg#arrow-left"
            sx={theme.header.icon}
          />
        </Button>

        <Heading as="h5" sx={theme.header.heading}>
          {title}
        </Heading>

        <Button
          variant="buttons.link.1"
          onClick={clearSelectedFilters}
          sx={theme.header.clearAll}
        >
          Clear All
        </Button>
      </Flex>
    );
  }
);

FiltersSidebarHeader.displayName = 'FiltersSidebarHeader';
