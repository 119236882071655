import { useMemo } from 'react';
import { Paragraph } from 'theme-ui';
import {
  useProductInventoryByHandle,
  useSettings,
} from '@backpackjs/storefront';

import { getEncodedId, isEncoded } from '@utils';

import { themed } from './Badge.theme';

export const Badge = themed(({ theme, handle, product, isPdp }) => {
  const settings = useSettings();
  const { customBadges, soldOutBadge, onSaleBadge } = {
    ...settings?.product?.badges,
  };
  const { inventory } = useProductInventoryByHandle({
    handle,
    withQuantity: true,
  });

  const variants = useMemo(() => {
    if (!product) return [];
    const _variants = [];
    product.variants?.find((variant) => {
      if (variant.product.handle === product.handle) {
        _variants.push(variant);
        return false;
      }
      return true;
    });
    return _variants;
  }, [product?.id]);

  const isSoldOut = useMemo(() => {
    if (!variants?.length || inventory?.variants?.length) return null;
    return variants.every((variant) => {
      const variantStorefrontId = isEncoded(inventory?.id)
        ? getEncodedId(variant.storefrontId)
        : variant.storefrontId;
      const variantInventory = inventory
        ? inventory?.variants?.[variantStorefrontId]
        : null;

      const variantSoldOut = variantInventory
        ? variantInventory.quantityAvailable <= 0 &&
          variant.inventoryPolicy !== 'CONTINUE' &&
          variantInventory.product?.handle !== 'brumate-e-gift-card'
        : false;

      return variantSoldOut;
    });
  }, [variants, inventory?.variants]);

  const selectedVariant = product?.variants?.[0];
  const price = selectedVariant?.priceV2?.amount;
  const compareAtPrice = selectedVariant?.compareAtPriceV2?.amount;

  const isOnSale = useMemo(
    () => parseFloat(price) < parseFloat(compareAtPrice),
    [price, compareAtPrice]
  );

  const badge = useMemo(() => {
    if (!product?.tags?.length || !customBadges?.length) return null;

    const shopifyTag = product?.tags?.find((tag) => tag.startsWith('badge::'));

    const match = customBadges.find((_badge) => _badge.tag === shopifyTag);

    if (match) return match;

    if (shopifyTag?.toLowerCase() === 'badge::new')
      return {
        text: 'New',
        color: '#ffffff',
        backgroundColor: '#83B784',
        tag: 'badge::New',
      };

    return match;
  }, [product?.id, customBadges]);

  switch (true) {
    case isSoldOut: {
      return (
        <Paragraph
          data-comp={Badge.displayName}
          sx={{
            ...theme.badge(isPdp),
            color: soldOutBadge?.color || 'white',
            bg: soldOutBadge?.backgroundColor || 'mediumDarkGray',
          }}
        >
          {soldOutBadge?.text || 'Sold Out'}
        </Paragraph>
      );
    }
    case isOnSale: {
      return (
        <Paragraph
          data-comp={Badge.displayName}
          sx={{
            ...theme.badge(isPdp),
            color: onSaleBadge?.color || 'white',
            bg: onSaleBadge?.backgroundColor || 'red',
          }}
        >
          {onSaleBadge?.text || 'Sale'}
        </Paragraph>
      );
    }
    case !!badge:
      return (
        <Paragraph
          data-comp={Badge.displayName}
          sx={{
            ...theme.badge(isPdp),
            color: badge?.color || 'white',
            bg: badge?.backgroundColor || 'black',
          }}
        >
          {badge?.text}
        </Paragraph>
      );
    default:
      return null;
  }
});

Badge.displayName = 'Badge';
