import { Container, Box } from 'theme-ui';

import { Section } from '@snippets';
import { withInView } from '@utils';

import { Schema } from './HalfMedia.schema';
import { themed } from './HalfMedia.theme';

import { Video } from './Video';
import { Content } from './Content';
import { Image } from './Image';

export const HalfMedia = withInView(
  themed(({ theme, cms, inView }) => {
    const { image, video, media, content, section, html } = cms;
    const mediaLeft = media?.locationDt === 'left'; // mobile
    const mediaTop = media?.location === 'top'; // desktop

    return (
      <Section section={section}>
        <Container
          data-comp={HalfMedia.displayName}
          sx={{
            ...theme.grid,
            gridTemplateAreas: [
              mediaTop ? '"media" "content"' : '"content" "media"',
              mediaLeft ? '"media content"' : '"content media"',
            ],
          }}
        >
          <Box
            sx={{
              ...theme.media,
              ':before': {
                content: '""',
                float: 'left',
                pb: [media?.ratio || '100%', 0],
              },
            }}
          >
            {!html?.html &&
              (inView && video?.enabled ? (
                <Video video={video} />
              ) : (
                <Image image={image} />
              ))}

            {html?.html && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
                dangerouslySetInnerHTML={{ __html: html?.html }}
              />
            )}
          </Box>

          <Box
            sx={{
              ...theme.content,
              justifyContent: mediaLeft ? 'flex-start' : 'flex-end',
              ':before': {
                content: '""',
                float: 'left',
                pb: [0, media?.ratioDt || '100%'],
              },
            }}
          >
            <Content content={content} />
          </Box>
        </Container>
      </Section>
    );
  }),
  { triggerOnce: true }
);

HalfMedia.displayName = 'HalfMedia';
HalfMedia.Schema = Schema;
