import { Box, Paragraph, Text, Button } from 'theme-ui';

import { Picture, Svg } from '@snippets';
import store, { useRecoilValue } from '@store';

import { themed } from './FilterOption.theme';

export const FilterOption = themed(
  ({
    theme,
    option,
    field,
    buttonSx,
    isMobile,
    addToSelectedFilters = () => {},
    removeFromSelectedFilters = () => {},
    ...props
  }) => {
    const swatchesMap = useRecoilValue(store.swatchesMap);

    const isColor = field === 'tags_colorfilter';
    const label = option.label?.toLowerCase();
    const swatchColor = isColor ? swatchesMap[label]?.color : null;
    const isWhite = swatchColor === '#FFFFFF';
    const inactiveBgColor = swatchColor || '#FFFFFF';
    const activeBgColor =
      inactiveBgColor === '#FFFFFF' ? 'text' : inactiveBgColor;
    const bgColor = option.active ? activeBgColor : inactiveBgColor;
    const borderColor =
      inactiveBgColor === '#FFFFFF' ? 'gray' : inactiveBgColor;
    const isImage = isColor ? !!swatchesMap[label]?.image?.src : false;

    return (
      <Button
        data-comp={FilterOption.displayName}
        variant="buttons.plain"
        onClick={() => {
          if (option.active) {
            removeFromSelectedFilters({
              field,
              option,
            });
          } else {
            addToSelectedFilters({
              field,
              option,
            });
          }
        }}
        tabIndex={props.tabIndex || '0'}
        aria-hidden={props['aria-hidden'] || 'false'}
        sx={{ ...theme.button, ...buttonSx }}
      >
        <Box sx={theme.swatchOuter(option.active)}>
          <Box
            sx={theme.swatchInner({
              isActive: option.active,
              isWhite,
              isImage,
              bgColor,
              borderColor,
            })}
          >
            {isImage && (
              <Picture
                alt={label}
                images={[
                  {
                    src: swatchesMap[label].image.src,
                    ratio: 1,
                    width: 24,
                  },
                ]}
                sx={theme.image(option.active)}
              />
            )}

            {option.active && (
              <Svg
                src="/svgs/checkmark.svg#checkmark"
                alt="Checkmark"
                viewBox="0 0 14 12"
                sx={theme.checkmark(isWhite)}
              />
            )}
          </Box>
        </Box>

        <Paragraph
          sx={{
            ...theme.label(option.active),
            color: option?.active
              ? 'text'
              : isMobile
              ? 'dark'
              : 'mediumDarkGray',
          }}
        >
          {option.label} <Text>({option.count})</Text>
        </Paragraph>
      </Button>
    );
  }
);

FilterOption.displayName = 'FilterOption';
